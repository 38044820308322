
body {
  background-color:rgb(34, 34, 34) !important;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
  display: grid;
  grid-template-rows: 100vh;
  background: linear-gradient(to bottom, #ffffff, #b1b1b1);
  overflow:auto !important;

  /*
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
}

@media all and (min-width:0px) and (max-width: 1024px) {
  body {
    overflow-x: auto !important;
    overflow-y: auto !important;
  }

  .navigationDiv {
    display: flex;
    justify-content:center;
    align-items: center;
    grid-column-gap: 25px;
    margin-top: 40vh;
    flex-wrap: wrap-reverse !important;
  }
  .navigationDiv Button {
    margin-top:10px !important;
  }

  .scrollTextBox {
    height: 35vh;
    overflow-y:auto;
    margin-left: 25px !important;
    margin-right: 25px !important;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .fieldContainer {
    width: 45vw !important;
    min-width: 400px !important;
    height: 35vh;
    /* border:3px solid black; */
    overflow-y:auto;
    overflow-x:hidden;
    padding: 20px;
    object-fit: cover;
    object-position: center;
  }
  .blackLine {
    width: 90% !important;
    min-width: 300px !important;
    height:2px;
    background-color:black;
    margin: 0 auto;
  }

  .containerFlex {
    justify-content: right !important;
    /*display: flex;
    flex-direction: column;*/
  }
}

body, html {
  overflow-x: hidden;
  overflow-y: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

h1 {
  color:rgb(43, 43, 43) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.whiteText {
  color: white;
}

.center {
  display: flex;
  justify-content:center;
  align-items: center;
}

#playButtonIcon {
  margin-top:115px;
}

/*
#playButtonIcon :hover {
  width:100px;
  height:100px;
}
*/

.blackLine {
  width: 60%;
  height:2px;
  background-color:black;
  margin: 0 auto;
}

.navigationGroup {
  display: flex;
  justify-content:center;
  align-items: center;
  grid-column-gap: 25px;
}

.navButton {
  width: 200px !important;
  height: 50px !important;
}

.navigationDiv {
  display: flex;
  justify-content:center;
  align-items: center;
  grid-column-gap: 25px;
  margin-top: 40vh;
}

.navigationDiv Button {
  color: black !important;
  transition: transform 0.3s;
  padding-left:20px;
  padding-right:20px;
  border-color:rgb(0, 0, 0);
  border-width: 2px;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  width:250px;
  background-color:rgb(190, 190, 190) !important;
  border-radius: 0;
}

.navigationDiv h3 {
  font-size: 40px;
}

.navigationDiv div :hover {
  /*transform: scale(1.2);*/
  color: white;
  border-color:rgb(0, 0, 0);
}

.navigationTop {
  width:100%;
  height:50px;
  display:flex;
  justify-content: center;
  align-items: center;
  outline:0px;
  background-color:rgb(17, 109, 139);
  margin: 0 !important;
  padding-top:35px !important;
  padding-bottom:35px !important;
  box-shadow: 0px 5px 5px rgb(165, 165, 165);
  background: linear-gradient(to right, #f67828, #e25303);
}


.navigationTop button {
  margin-left:10px;
  margin-right: 10px;

  background-color: transparent;
  color:black;
  border:none;
  padding-right:20px;
  padding-left:20px;
}

.navigationTop button:hover {
  background-color:transparent !important;
}

/*
.navigationTop div :hover {
  /*transform: scale(1.1);
}
*/

.scrollTextBox {
  height: 35vh;
  overflow-y:auto;
  margin-left: 25vw;
  margin-right:25vw;
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 300px;

}

.fieldContainer {
  width: 25vw;
  height: 35vh;
  /* border:3px solid black; */
  overflow-y:auto;
  overflow-x:hidden;
  padding: 20px;
  position: relative;
  min-Height: 300px;
}

.projektObject {
  width: 10vw;
  height: 10vw;
  min-width: 150px;
  min-height: 150px;
  padding: 20px;
  border: 3px solid rgb(0, 0, 0);
  margin-left:5px;
  margin-right:5px;
  margin-top:5px;
  margin-bottom: 5px;
}

.imageDisplay {
  display: flex;
  flex-direction: column;
  width:100%;
  height:20vh;
}

.imageStyle {
    border:2px solid black;
    width:100%;
    height: auto;
    flex: 1 0 auto;
    object-fit: cover;
}
.imageSlider {
  width: 24vw;
  height: 12px;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top:10px;
  background: transparent;
  flex-shrink: 0;
}

.imageScrollButton {
  width:25px;
  height:25px;
  border-radius: 0 !important;
  background-color: rgb(246, 120, 40) !important;
  border: 1px solid black !important;
  margin-top:0px;
  margin-left:5px;
  margin-right:5px;
}

.imageScrollButton:hover {
  transform: scale(1.2);
  background-color: rgb(226, 83, 3) !important;
}

.imageStyle:hover {
  cursor: pointer;
}

.mainContainer {
  min-width: 750px;
}